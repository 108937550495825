<script setup lang="ts">
import { ChevronLeftIcon } from 'lucide-vue-next';
import type { NavigationItemQuery } from '#gql';

withDefaults(defineProps<{
  items?: NonNullable<NonNullable<NonNullable<NavigationItemQuery['navigationItem']>['groups']>[number]>['columns'];
  parent?: string | null;
}>(), { items: [],parent: '', })

const {
  setIsBurgerNavigationVisible,
  setThirdLevel,
} = useBurgerNavigation();

const { getStoryblokUrl } = useStoryblokData();

const calculateRunningIndex = (outerIndex: number, innerIndex: number): number => {
  let runningIndex = 0;
  __props.items?.forEach((outerItem, i) => {
    if (i < outerIndex && outerItem.blocks) {
      runningIndex += outerItem.blocks.length;
    }
  });
  runningIndex += innerIndex;
  return runningIndex;
};

const isLastItem = (outerIndex: number, innerIndex: number) => {
  const runningIndex = calculateRunningIndex(outerIndex, innerIndex);
  const totalItemCount = __props.items?.reduce(
    (total, outerItem) => total + (outerItem.blocks?.length || 0),
    0,
  ) || 0;
  return runningIndex === totalItemCount - 1;
};
</script>

<template>
  <div class="burger-menu-third-level relative -right-full mb-4 h-auto bg-white">
    <span
      class="flex cursor-pointer items-center bg-grey-light p-4"
      @click="setThirdLevel(-1)"
    >
      <ChevronLeftIcon class="mr-4 size-4 text-dark" />
      <span
        class="text-sm font-semibold text-dark"
        v-text="parent"
      />
    </span>
    <span
      v-for="(item, index) in items"
      :key="item.id"
      class="flex w-full cursor-pointer flex-col items-center justify-between"
      @click="setThirdLevel(index)"
    >
      <span
        v-for="(block, blockIndex) in item.blocks"
        :key="block.id"
        class="block w-full p-4"
        :class="{ ' border-b border-b-grey': !isLastItem(index, blockIndex) }"
      >
        <NuxtLink
          class="cursor-default"
          :to="getStoryblokUrl(block.link)"
          @click="setIsBurgerNavigationVisible(false)"
        >
          <span
            class="text-sm font-semibold text-dark"
            :class="{ 'cursor-pointer hover:text-semidark': block.link }"
            v-text="block.title"
          />
        </NuxtLink>
        <span
          v-for="flyoutLink in block.links"
          :key="flyoutLink.id"
        >
          <NuxtLink
            class="mt-2 block w-full cursor-pointer text-xs leading-4"
            :to="getStoryblokUrl(flyoutLink.link)"
            @click="setIsBurgerNavigationVisible(false)"
          >
            <span
              class="cursor-pointer text-grey-semidark hover:text-grey-dark"
              v-text="flyoutLink.title"
            />
          </NuxtLink>
        </span>
      </span>
    </span>
  </div>
</template>
